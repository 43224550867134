@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

$colorPrimary: #f5801e;
$colorPrimaryLight: #1585d8;
$colorDanger: #d14140;
$borderColor: #d8dce2;

%whiteStrip {
  background-color: #ffffff;
  padding: 3rem;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  max-width: 80%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
  text-align: center;
  margin: 0 auto;
}

/* Media Breakpoints */
@mixin bp($point) {
  @if $point == md-screen {
    @media (max-width: 1166px) {
      @content;
    }
  } @else if $point == md1240-screen {
    @media (max-width: 1240px) {
      @content;
    }
  } @else if $point == sm-screen {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $point == sm800-screen {
    @media (max-width: 800px) {
      @content;
    }
  } @else if $point == mobile-screen {
    @media (max-width: 680px) {
      @content;
    }
  } @else if $point == mobile-sm-screen {
    @media (max-width: 480px) {
      @content;
    }
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #f6f8fa;
  font-family: 'Inter', sans-serif;
}

.img-full {
  max-width: 100%;
}

.landingPage {
  background-color: #fafafa;

  &__section {
    padding: 1.25rem 2.5rem;
    @include bp(mobile-screen) {
      padding: 1.25rem 1.5rem;
    }
    .section-heading {
      font-size: 1.125rem;
      font-family: 'Unbounded', cursive;
      padding-bottom: 2.25rem;
      text-align: center;
    }

    .section-sub-heading {
      font-size: 2.25rem;
      text-transform: capitalize;
      font-weight: 600;
      text-align: center;
    }

    .main-banner-content {
      .banner-heading {
        font-size: 3rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        font-family: 'Unbounded', cursive;
        margin-bottom: 2.25rem;
        @include bp(mobile-sm-screen) {
          font-size: 2rem;
        }
        .light {
          font-weight: 300;
        }
      }
      .banner-description {
        font-size: 1.125rem;
        color: #21262b;
        margin-bottom: 2.25rem;
      }
      .download_details {
        font-size: 0.875rem;
        font-weight: 400;
        color: #21262b;
        display: block;
        margin-top: 2.25rem;
      }
    }

    // two sections
    .left-section {
      width: 50%;
      @include bp(sm800-screen) {
        width: 100%;
        text-align: center;
      }
    }

    .right-section {
      width: 50%;
      @include bp(sm800-screen) {
        width: 100%;
        text-align: center;
        margin-bottom: 1.5rem;
      }
    }

    &.pricing {
      background: #21262b;
      color: #ffffff;
      padding: 5rem 2.5rem;
      @include bp(mobile-screen) {
        padding: 3rem 1.5rem;
      }
      .pricing-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1.5rem;
        margin: 3.75rem 0;
        @include bp(md1240-screen) {
          grid-template-columns: repeat(4, 1fr);
          // grid-gap: 3rem;
        }
        @include bp(md-screen) {
          grid-template-columns: repeat(2, 1fr);
          // grid-gap: 3rem;
        }
        @include bp(sm-screen) {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 3rem;
        }
        @include bp(mobile-sm-screen) {
          grid-template-columns: repeat(1, 1fr);
        }
        &__card {
          padding: 1.5rem 1.5rem 5rem 1.5rem;
          border-radius: 4px;
          box-shadow: 2px 2px 0 0 rgba(255, 255, 255, 0.4);
          position: relative;
          &__heading {
            font-family: 'Unbounded', cursive;
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.44;
            letter-spacing: normal;
            color: #fff;
            margin-bottom: 2.25rem;
          }
          &__pricing {
            font-size: 2.25rem;
            font-weight: 600;
            margin-bottom: 2.25rem;
            sup {
              font-size: 1rem;
              line-height: 1.5;
            }
            .per-month {
              font-size: 1rem;
              line-height: 1.5;
              font-weight: 400;
            }
          }
          &__description {
            font-size: 0.875rem;
            line-height: 1.57;
            min-height: 11.625rem;
            margin-bottom: 1rem;
            p {
              padding-bottom: 1.5rem;
            }
          }
          .btn__pricing {
            padding: 0.75rem;
            border-radius: 4px;
            border: solid 1px #df761e;
            background-image: linear-gradient(to bottom, #f94, #f5801e);
            font-size: 1.125rem;
            line-height: 1.5;
            font-weight: 600;
            width: calc(100% - 3rem);
            color: #fff;
            cursor: pointer;
            transition: box-shadow 0.3s ease-in-out;
            position: absolute;
            left: 1.5rem;
            bottom: 1.5rem;
            &:hover {
              box-shadow: 0 0 15px #7463cb;
            }
          }
        }
      }
      .free-forever {
        display: flex;
        @include bp(mobile-sm-screen) {
          flex-direction: column;
        }
        &__heading {
          width: 150px;
          font-family: 'Unbounded', cursive;
          font-weight: 1.125rem;
          font-weight: 600;
          @include bp(mobile-sm-screen) {
            width: 100%;
            margin-bottom: 1rem;
          }
        }
        &__description {
          width: calc(100% - 150px);
          font-size: 0.875rem;
          line-height: 1.57;
          @include bp(mobile-sm-screen) {
            width: 100%;
          }
          p {
            margin-bottom: 1.5rem;
          }
        }
        .link-btn {
          font-weight: 600;
        }
      }
    }

    &.features {
      padding: 5rem 2.5rem;
      @include bp(mobile-screen) {
        padding: 3rem 1.5rem;
      }
      .features-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 5rem;
        grid-row-gap: 2.25rem;
        margin: 3.75rem 0;
        @include bp(sm-screen) {
          grid-template-columns: repeat(2, 1fr);
        }
        @include bp(mobile-sm-screen) {
          grid-template-columns: repeat(1, 1fr);
        }
        &__card {
          text-align: center;
          &__heading {
            padding-bottom: 0.75rem;
            font-size: 1.125rem;
            font-weight: 600;
          }
          &__content {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.57;
            color: #595c60;
          }
        }
      }
    }
    &.services {
      padding: 5rem 2.5rem;
      @include bp(mobile-screen) {
        padding: 3rem 1.5rem;
      }
      .flex-40-60 {
        display: flex;
        margin-bottom: 3rem;
        gap: 1.5rem;
        @include bp(mobile-sm-screen) {
          flex-direction: column;
        }
        .col-1 {
          width: 40%;
        }
        .col-2 {
          width: 60%;
        }
        @include bp(sm-screen) {
          .col-1,
          .col-2 {
            width: 50%;
          }
        }
        @include bp(mobile-sm-screen) {
          .col-1,
          .col-2 {
            width: 100%;
          }
        }
      }
      .services-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1.5rem;
        @include bp(sm-screen) {
          grid-template-columns: repeat(2, 1fr);
        }
        @include bp(mobile-sm-screen) {
          grid-template-columns: repeat(1, 1fr);
        }
        &__card {
          padding: 1.5rem;
          border-radius: 4px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
          background-color: #fff;
          &__icon {
            display: inline-block;
            font-size: 2.5rem;
            color: #bbc4d0;
            margin-bottom: 1.5rem;
          }
          &__heading {
            font-size: 1.125rem;
            line-height: 1.625rem;
            margin-bottom: 0.5rem;
          }
          p {
            font-size: 0.875rem;
            color: #595c60;
            line-height: 1.375rem;
          }
        }
      }
    }
    &.faqs {
      padding: 5rem 1.5rem;
    }
  }

  .summary-strip {
    @extend %whiteStrip;
    @include bp(mobile-screen) {
      grid-template-columns: repeat(2, 1fr);
      padding: 2rem;
      max-width: 100%;
    }
    &__card {
      color: #21262b;
      &__heading {
        font-size: 2.25rem;
        font-weight: 600;
      }
      &__caption {
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
  }

  .navbar {
    padding: 1.25rem 2.5rem;
    @include bp(mobile-screen) {
      padding: 1.25rem 1.5rem;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include bp(mobile-screen) {
      padding: 1.25rem 1.5rem;
    }
    .mobile-hamburger {
      display: none;
      border: none;
      background: none;
      font-size: 1.5rem;
    }
    .navigation {
      padding: 0;
      margin: 0;
      list-style: none;
      @include bp(mobile-screen) {
        display: none;
      }
      li {
        color: black;
        text-decoration: none;
        display: inline-block;
        padding: 0.75rem 1.5rem;
      }
    }
    @include bp(mobile-screen) {
      .navigation {
        display: flex;
        position: absolute;
        left: 0;
        background: #fff;
        /* height: 100%; */
        flex-direction: column;
        width: 100%;
        text-align: left;
        top: 90px;
        height: 100%;
        padding-top: 20px;
        display: none;
      }

      .signup-btn {
        display: none;
      }
      .mobile-hamburger {
        display: block;
      }
    }
  }
  .section-heading {
    font-size: 1.125rem;
    text-transform: uppercase;
    line-height: 1.625rem;
    &.blue {
      color: $colorPrimary;
    }
  }
}

#mainSection .two-section-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../assets/images/main-header-shape.svg) center bottom no-repeat;
  background-size: 100% auto;
  @include bp(sm800-screen) {
    flex-direction: column-reverse;
  }
}

.btn {
  &__primary {
    border-radius: 0.25rem !important;
    // box-shadow: 2px 2px 0 0 #21262b !important;
    border: solid 1px #df761e;
    background-image: linear-gradient(to bottom, #f94, #f5801e);
    color: #ffffff !important;
    padding: 0.625rem 1.5rem !important;
  }
}

.blue_bg {
  background-image: linear-gradient(to bottom, rgba(237, 242, 246, 0), #edf2f6);
  padding: 1.5rem;
}

.we-are-here {
  @extend %whiteStrip;
  justify-content: flex-start;
  grid-template-columns: repeat(2, 1fr);
  @include bp(mobile-screen) {
    max-width: 100%;
  }
  @include bp(mobile-sm-screen) {
    grid-template-columns: repeat(1, 1fr);
  }
  &__heading {
    text-align: left;
  }
  &__details {
    text-align: left;
    .link {
      font-weight: 600;
      color: #21262b;
    }
    .small-txt {
      font-size: 0.875rem;
      color: #595c60;
      margin-top: 1.125rem;
    }
  }
}

.faqs-wrapper {
  max-width: 80%;
  margin: 0 auto;
  &__item {
    border-bottom: 1px solid #ccc;
    transition: 0.5s all;
    .question {
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      padding: 1.125rem 1.5rem 1.125rem 0;
      transition: 0.5s all;
      position: relative;
      em {
        font-size: 1.125rem;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
    .answer {
      display: none;
      font-size: 0.875rem;
      line-height: 1.375rem;
      padding: 0 0 1.5rem 0;
    }
    &:hover {
      border-bottom: 1px solid $colorPrimary;
      .question {
        color: $colorPrimary;
      }
    }
    &.active {
      .answer {
        display: block;
      }
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 2.5rem;
  @include bp(mobile-sm-screen) {
    flex-direction: column;
    align-items: flex-start;
  }
  &__logo {
    display: flex;
    align-items: center;
    color: #595c60;
    @include bp(mobile-sm-screen) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1.5rem;
      gap: 1rem;
    }
  }
  &__nav {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding: 0.5rem 1.25rem;
      display: inline-block;
      @include bp(mobile-sm-screen) {
        display: block;
        padding: 0.5rem 1.25rem 0.5rem 0;
        margin-bottom: 0.5rem;
      }
    }
  }
}
