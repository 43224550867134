/* Spinner CSS */

.loading {
  position: relative !important;
  height: 50px !important;
  width: 200px !important;
  top: 0px !important;
  left: 0px !important;
  background-image: none !important;
  border: none !important;
  outline: none !important;
  background-color: #4e3da5 !important;
  color: white !important;
  text-transform: uppercase !important;
  font-size: 20px !important;
  letter-spacing: 2px !important;
  cursor: pointer !important;
  transition: all 0.2s ease-out !important;
}
.loading {
  border-radius: 50px !important;
  width: 50px !important;
}
.loading::after {
  width: 40px !important;
  left: 5px !important;
  top: 12px !important;
  border-radius: 100% !important;
}
.spinner {
  display: block !important;
  width: 25px !important;
  height: 25px !important;
  position: absolute !important;
  top: 8px !important;
  left: calc(50% - 17px) !important;
  background: transparent !important;
  box-sizing: border-box !important;
  border-top: 4px solid white !important;
  border-left: 4px solid transparent !important;
  border-right: 4px solid transparent !important;
  border-bottom: 4px solid transparent !important;
  border-radius: 100% !important;
  animation: spin 0.6s ease-out infinite !important;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.section3 {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  padding: 30px 0 !important;
}
