body {
  margin: 0px !important;
}

#improveTextContainer .langPluginModal {
  background: #fff !important;
  color: #000 !important;
  width: 800px !important;
  max-width: 1000px !important;
  border-radius: 8px !important;
  font-family: "Inter", sans-serif !important;
  font-size: 15px !important;
  line-height: 1.2 !important;
  max-width: 80vw !important;
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 9999 !important;
  -webkit-box-shadow: rgba(0, 0, 0, 0.38) 1px 1px 15px 0px !important; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: rgba(0, 0, 0, 0.38) 1px 1px 15px 0px !important; /* Firefox 3.5 - 3.6 */
  box-shadow: rgba(0, 0, 0, 0.38) 1px 1px 15px 0px !important; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}
.langPluginModal__header {
  border-radius: 8px 8px 0px 0px !important;
  padding: 15px !important;
  border-bottom: 1px solid #21262b !important;
  position: relative !important;
  background: #21262b !important;
}
.langPluginModal_dropdown.open .langPluginModal_dropdown__menu {
  z-index: 9;
}
.langPluginModal__header .langPluginModal__dropdown {
  /* padding: 15px 10px!important; */
  background: #21262b !important;
  border-color: #21262b !important;
}
/* .langPluginModal__header .langPluginModal__dropdown.open{
  background: #463794!important;
  border-color: #463794!important;
} */
.langPluginModal__header
  .langPluginModal__dropdown
  .langPluginModal__dropdown__label {
  font-size: 20px !important;
  color: #fff !important;
  justify-content: space-around !important;
}
.langPluginModal__header
  .langPluginModal__dropdown.open
  .langPluginModal__dropdown__label {
  justify-content: space-around !important;
}
.langPluginModal__header
  .langPluginModal__dropdown
  .langPluginModal__dropdown__menu {
  top: 50px !important;
}
.langPluginModal__header
  .langPluginModal__dropdown
  .langPluginModal__dropdown__label
  .icon-container {
  padding: 5px 7px !important;
  border: 1px solid #fff !important;
  border-radius: 5px !important;
  margin-left: 7px !important;
}
.langPluginModal__header__close {
  position: absolute !important;
  right: 24px !important;
  top: 20px !important;
  font-size: 1.5rem !important;
  width: 30px !important;
  cursor: pointer !important;
  margin-top: 8px !important;
}
.langPluginModal__header__close img {
  width: 100% !important;
}
.langPluginModal__header h1 {
  font-weight: 600 !important;
  margin: 0 !important;
  color: #000 !important;
  line-height: inherit !important;
  font-size: 18px !important;
}
.langPluginModal__body {
  padding: 1.5rem 1.5rem 0 1.5rem !important;
}

.first-filter,
.second-filter,
.third-filter {
  display: flex !important;
  align-items: center !important;
}

.first-filter {
  text-align: left !important;
}

.second-filter {
  text-align: center !important;
}

.third-filter {
  text-align: right !important;
}

.langPluginModal__footer {
  padding: 1.5em !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.langPluginModal__footer__copyright {
  font-size: 0.75em !important;
  color: #a6a8aa !important;
}
.langPluginModal__dropdown {
  border: 1px solid #ccc !important;
  background: #fff !important;
  border-radius: 4px !important;
  padding: 10px 10px !important;
  display: inline-block !important;
  position: relative !important;
  cursor: pointer !important;
}
.langPluginModal__dropdown .up {
  display: none !important;
  margin: 0 !important;
  padding: 0 !important;
}
.langPluginModal__dropdown .down {
  display: inline !important;
  margin: 0 !important;
  padding: 0 !important;
}
.langPluginModal__dropdown.open .up {
  display: inline !important;
  margin: 0 !important;
  padding: 0 !important;
}
.langPluginModal__dropdown.open .down {
  display: none !important;
  margin: 0 !important;
  padding: 0 !important;
}
.langPluginModal__dropdown__label {
  font-weight: 600 !important;
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  text-transform: capitalize !important;
}
.langPluginModal__dropdown__label img {
  width: 14px !important;
}
/* .langPluginModal__dropdown__label .dropdown-arrow {
    position: absolute !important;
    right: 0 !important;
    top: -1px !important;
    font-size: 1.5rem !important;
} */
.langPluginModal__dropdown__menu {
  display: none !important;
  width: 100% !important;
  min-width: 100px !important;
  background-color: #fff !important;
  position: absolute !important;
  left: 0 !important;
  top: 40px !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08) !important;
  border: solid 1px #d8dce2 !important;
  background-color: #fff !important;
  border-radius: 5px;
  overflow: hidden;
}
.langPluginModal__dropdown__menu ul {
  margin: 0 !important;
  padding-left: 0 !important;
  list-style: none !important;
  max-height: 300px !important;
  overflow: auto !important;
  position: relative !important;
  z-index: 1 !important;
}
.langPluginModal__dropdown__menu ul li,
.dropdown-item {
  padding: 8px 30px 8px 11px !important;
  border-bottom: 1px solid #d8dce2 !important;
  background: #fff !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 8px 11px 8px 11px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.57 !important;
  letter-spacing: normal !important;
  color: #21262b !important;
}
.langPluginModal__dropdown__menu ul li img.item-tick,
.dropdown-item img.item-tick {
  width: 14px !important;
  display: none !important;
}
.langPluginModal__dropdown__menu ul li:hover img.item-tick,
.dropdown-item:hover img.item-tick {
  display: inline !important;
}
img.item-tick {
  width: 14px !important;
  display: none !important;
}
img.item-tick {
  display: inline !important;
}
.langPluginModal__dropdown__menu ul li:hover,
.dropdown-item:hover {
  background-color: #f3f6fb !important;
  color: #267ff4 !important;
}
.langPluginModal__dropdown.open .langPluginModal__dropdown__menu {
  min-width: 220px !important;
  display: block !important;
  z-index: 10 !important;
}
.langPluginModal__body .rendered-content p {
  position: relative !important;
  margin: 10px 0px !important;
  line-height: 1.5 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px !important;
  overflow: auto !important;
}
.rendered-content {
  position: relative !important;
}

.large-output {
  display: block !important;
}

.small-output {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.improveTextWrapper {
  border: 1px solid #ccc !important;
  margin-top: 24px !important;
  border-radius: 5px !important;
  overflow: visible !important;
}
.improveTextWrapper textarea {
  color: #000 !important;
  width: 100% !important;
  padding: 15px !important;
  box-sizing: border-box !important;
  border: none !important;
  overflow: auto !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  resize: none !important;
  background-color: #fff !important;
  /*remove the resize handle on the bottom right*/
  min-height: 135px !important;
  max-height: 135px !important;
  height: 135px !important;
}
.improveTextWrapper__footer {
  background: #f3f6fb !important;
  padding: 0.75rem 1rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  box-shadow: 0 -1px 0 0 #d8dce2 !important;
}
.improveTextWrapper__footer .word-limit label {
  color: #595c60 !important;
  font-weight: 500 !important;
}
.main-buttons {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  max-width: 65% !important;
  margin-top: 0px !important;
}
.buttons-wrapper {
  display: flex !important;
  justify-content: flex-end !important;
}
.btn-helper-text {
  font-size: 12px !important;
  margin-top: 5px !important;
  color: #777;
}
.baseBackgroundColor__btn {
  width: 150px !important;
  height: 40px !important;
  margin: 0 10px 0 10px !important;
  padding: 0px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  border: solid 1px #ff9944 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  background-color: #ff9944 !important;
}
.improveTextWrapper__btn {
  width: 180px !important;
  height: 40px !important;
  margin: 0 10px 0 10px !important;
  padding: 0px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  border: solid 1px #1585d8 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  background-color: #2da1f8 !important;
  /* background-image: linear-gradient(to top, #1991eb, #2da1f8) !important; */
}

.improveTextWrapper__btn:hover {
  background-image: linear-gradient(to bottom, #1584d7, #2184cf 20%) !important;
}
.improveTextWrapper__btn:active {
  background-image: linear-gradient(
    to bottom,
    #0c73c1,
    #196baa -33%
  ) !important;
}
.improveTextWrapper__btn:disabled {
  border: solid 1px #d8dce2 !important;
  background-color: #e3e8ed !important;
  background-image: unset !important;
  color: #21262b !important;
  pointer-events: none !important;
}
.baseBackgroundColor__btn:hover {
  background-image: linear-gradient(
    to bottom,
    #ff9944,
    #f5801e -33%
  ) !important;
}
.baseBackgroundColor__btn:active {
  background-image: linear-gradient(
    to bottom,
    #ff9944,
    #f5801e -33%
  ) !important;
}
.langPluginModal__dropdown {
  min-width: 130px;
}
.show-regenerate {
  padding: 0 1rem 0.75rem 1rem !important;
}
.show-regenerate .improveTextWrapper__btn {
  width: 150px !important;
}
.show-regenerate .main-buttons {
  max-width: 65% !important;
  margin-top: 18px !important;
}
.show-regenerate #regenerate {
  display: block !important;
}
.show-regenerate .btn-helper-text {
  display: block !important;
}
.flat-button {
  height: 40px !important;
  margin: 0 0 0 24px !important;
  padding: 8px 26px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  font-weight: 600 !important;
  border: solid 1px #d8dce2 !important;
  background-image: linear-gradient(to top, #f2f4f7, #fff) !important;
  padding-left: 50px !important;
  color: rgb(0, 0, 0) !important;
  min-width: 155px !important;
}
.flat-button__text {
  display: inline-block !important;
  position: relative !important;
}
.flat-button__text img {
  position: absolute !important;
  width: 25px !important;
  top: -3px !important;
  left: -35px !important;
}

.flat-button__text em {
  position: absolute !important;
  left: 0 !important;
  top: -2px !important;
  font-size: 1.25rem !important;
}
.flat-button:hover {
  background-image: linear-gradient(to top, #eaeef3, #fff) !important;
}
.flat-button:active {
  background-image: linear-gradient(
    to top,
    #e0e4e8 50%,
    #f2f7fa 150%
  ) !important;
}
.dropdownStyleModal {
  width: 312px !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08) !important;
  background-color: #fff !important;
  font-family: "Inter", sans-serif !important;
}
.dropdownStyleModal__header {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 1.125rem 1.125rem 1.125rem 1.125rem !important;
  border-bottom: 1px solid #d8dce2 !important;
  background-color: #21262b;
}
.dropdownStyleModal__header .version {
  font-size: 0.75rem !important;
  color: #000 !important;
  opacity: 0.5 !important;
}
.dropdownStyleModal__body {
  padding: 1.125rem !important;
}
.dropdownStyleModal__list {
  list-style: none !important;
  padding: 10px 0 !important;
  margin: 1rem 0 0 0 !important;
  border-top: 1px solid #d8dce2 !important;
  border-bottom: 1px solid #d8dce2 !important;
}
.dropdownStyleModal__list__item {
  padding: 10px 0 !important;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  transition: 0.5s all !important;
}
.dropdownStyleModal__list__item:hover {
  background: #d8dce2 !important;
}
.dropdownStyleModal__list__item > em {
  font-size: 1.5rem !important;
  margin-right: 1.125rem !important;
}
.dropdownStyleModal__list.premium {
  margin-top: 0 !important;
  border-top: none !important;
}
.dropdownStyleModal__btn {
  width: 100% !important;
  height: 40px !important;
  padding: 10px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  border: solid 1px #1585d8 !important;
  color: #fff !important;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  background-image: linear-gradient(to top, #1991eb, #2da1f8) !important;
}
.dropdownStyleModal__btn:hover {
  background-image: linear-gradient(to bottom, #1584d7, #2184cf 20%) !important;
}
.dropdownStyleModal__btn:active {
  background-image: linear-gradient(
    to bottom,
    #0c73c1,
    #196baa -33%
  ) !important;
}
.dropdownStyleModal__btn:disabled {
  border: solid 1px #d8dce2 !important;
  background-color: #e3e8ed !important;
  background-image: unset !important;
  color: #21262b !important;
  pointer-events: none !important;
}
.dropdownStyleModal__Upgrade {
  font-size: 0.8rem !important;
  color: #595c60 !important;
}

.dropdownStyleModal__Upgrade button {
  padding: 10px 0 !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  font-weight: 600 !important;
  border: solid 1px #d8dce2 !important;
  border: 1px solid rgba(42, 97, 48, 1);
  background-image: linear-gradient(
    130deg,
    rgba(42, 97, 48, 1) 50%,
    rgba(99, 162, 106, 1) 100%
  ) !important;
  color: #ffffff !important;
  letter-spacing: 1px;
  width: 100%;
}

.dropdownStyleModal__Upgrade button:hover {
  box-shadow: 0 0 5px rgba(42, 97, 48, 1);
}

.dropdownStyleModal__footer {
  margin-top: 1.125rem !important;
  display: flex !important;
  justify-content: space-between !important;
}
.dropdownStyleModal__footer a {
  font-size: 0.75rem !important;
  color: #595c60 !important;
  text-decoration: none !important;
}
.frmSign__formGroup {
  margin-bottom: 1.125rem !important;
}
.frmSign__formGroup input {
  padding: 10px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  border: 1px solid #dadada !important;
  background: #fff !important;
  border-radius: 4px !important;
}
.frmSign__formGroup:last-child {
  margin-bottom: 0 !important;
}
.frmSign__btn {
  width: 100% !important;
  height: 40px !important;
  padding: 10px !important;
  border-radius: 4px !important;
  margin-top: 1rem !important;
  cursor: pointer !important;
  color: #fff !important;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  border: solid 1px #df761e !important;
  background-image: linear-gradient(to bottom, #f94, #f5801e) !important;
}
.frmSign__btn:hover {
  background-image: unset !important;
  border: solid 1px #df761e;
  background-color: #f5801e;
}
.frmSign__btn:active {
  background-image: unset !important;
  border: solid 1px #bb641b;
  background-color: #df761e;
}
.frmSign__btn:disabled {
  border: solid 1px #d8dce2 !important;
  background-color: #e3e8ed !important;
  background-image: unset !important;
  color: #21262b !important;
  pointer-events: none !important;
}
.frmSign__title {
  font-size: 1.125rem !important;
  margin-top: 0 !important;
}
.frmSign__forgetPassword {
  font-size: 0.75rem !important;
  margin-top: 0.625rem !important;
  text-align: right !important;
}
.frmSign__forgetPassword a {
  color: #21262b !important;
  text-decoration: none !important;
}
.notMember {
  font-size: 0.875rem !important;
  text-align: center !important;
}
.notMember a {
  color: #21262b !important;
  text-decoration: none !important;
  font-weight: 600 !important;
}
.row_custom {
  display: flex !important;
}
.row_custom label {
  font-weight: 400 !important;
}
.row_custom .col-6 {
  width: 50% !important;
}
.row_custom .col-3 {
  width: 25% !important;
}
.row_custom .col-4 {
  width: 33.33% !important;
}
.row_custom .col-5 {
  width: 41.66% !important;
}
.text-right {
  text-align: right !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.textLight {
  font-weight: 400 !important;
}
.txtBold {
  font-weight: bold !important;
}

/* Spinner CSS */

.phz-loading {
  position: relative !important;
  height: 50px !important;
  width: 200px !important;
  top: 0px !important;
  left: 0px !important;
  background-image: none !important;
  border: none !important;
  outline: none !important;
  background-color: #21262b !important;
  color: white !important;
  text-transform: uppercase !important;
  font-size: 20px !important;
  letter-spacing: 2px !important;
  cursor: pointer !important;
  transition: all 0.2s ease-out !important;
}
.phz-loading {
  border-radius: 50px !important;
  width: 50px !important;
}
.phz-loading::after {
  width: 40px !important;
  left: 5px !important;
  top: 12px !important;
  border-radius: 100% !important;
}
.phz-loading .spinner {
  display: block !important;
  width: 34px !important;
  height: 34px !important;
  position: absolute !important;
  top: 8px !important;
  left: calc(50% - 17px) !important;
  background: transparent !important;
  box-sizing: border-box !important;
  border-top: 4px solid white !important;
  border-left: 4px solid transparent !important;
  border-right: 4px solid transparent !important;
  border-bottom: 4px solid transparent !important;
  border-radius: 100% !important;
  animation: spin 0.6s ease-out infinite !important;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.section3 {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  padding: 30px 0 !important;
}
.section0 {
  flex-direction: column !important;
}
.section0 h1 {
  margin-top: 0 !important;
}
.section0 h3 {
  margin: 0 !important;
}
.section0 .words-available {
  font-weight: 600 !important;
}
.color-red {
  color: #dc143c !important;
}
.color-green {
  color: #2e8b57 !important;
}

img:not([alt]) {
  filter: none !important;
}
#phrazify-backdrop {
  position: fixed !important;
  top: 0px !important;
  left: 0px !important;
  width: 100vw !important;
  height: 100vh !important;
  background: #0000008c !important;
  z-index: 999 !important;
}

button,
textarea {
  -webkit-appearance: none !important;
}

#notification {
  background: linear-gradient(130deg, #ff9944 50%, #f5801e 100%) !important;
  color: #ffffff !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  border: 1px solid #f5801e !important;
  padding: 10px 30px !important;
  position: fixed !important;
  bottom: 10px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  letter-spacing: 1px !important;
  z-index: 99999999 !important;
}

#copy-clipboard {
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 5px 10px !important;
  border-radius: 2px !important;
  background-color: #ff9944 !important;
  color: #ffffff !important;
  display: none;
}

.view-account {
  color: #ff9944 !important;
  text-decoration: none !important;
  margin-top: 15px;
  -webkit-transition: color 200ms linear;
  -ms-transition: color 200ms linear;
  transition: color 200ms linear;
  font-size: 13px !important;
  font-weight: 600;
}

.view-account:hover {
  color: #000000 !important;
}

#improveTextContainer span {
  outline: none !important;
}

#word-limit-error {
  position: absolute !important;
  bottom: 11px !important;
  color: #ff9944 !important;
  font-weight: 600 !important;
}

#phrazify-error {
  position: absolute !important;
  bottom: 11px !important;
  color: #ff0000 !important;
  font-weight: 600 !important;
}

.main-nav {
  justify-content: flex-start !important;
}

.notification-main-container {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.logo-center-div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.phrazify-pin-text {
  position: absolute;
  bottom: 100px;
  left: 0;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  font-size: 33px;
  left: 0;
  width: 100%;
  letter-spacing: 2px;
}

.langPluginModal__dropdownBtn {
  color: #000 !important;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0 !important;
}
.dropdown-menu {
  width: 220px !important;
  /* width: 100% !important; */
  min-width: 100px !important;
  background-color: #fff !important;
  left: 0 !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08) !important;
  border: solid 1px #d8dce2 !important;
  background-color: #fff !important;
  border-radius: 5px;
  overflow: hidden;
  padding: 0 !important;
}

.scrollable-dropdown-menu {
  height: auto;
  max-height: 300px;
  overflow-y: scroll;
}

.word-limit {
  display: flex;
  align-items: center;
}
