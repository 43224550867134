@font-face {
  font-family: 'ext-icons';
  src: url('../assets/fonts/ext-icons.eot?6tzkrp');
  src:
    url('../assets/fonts/ext-icons.eot?6tzkrp#iefix') format('embedded-opentype'),
    url('../assets/fonts/ext-icons.ttf?6tzkrp') format('truetype'),
    url('../assets/fonts/ext-icons.woff?6tzkrp') format('woff'),
    url('../assets/fonts/ext-icons.svg?6tzkrp#ext_icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ext-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ic-ask:before {
  content: '\e900';
  color: #a6a8aa;
}
.icon-ic-check:before {
  content: '\e901';
  color: #2996e6;
}
.icon-ic-close:before {
  content: '\e902';
}
.icon-ic-copy:before {
  content: '\e903';
}
.icon-ic-down-arrow:before {
  content: '\e904';
}
.icon-ic-improve-email:before {
  content: '\e905';
  color: #a6a8aa;
}
.icon-ic-improve-text:before {
  content: '\e906';
  color: #a6a8aa;
}
.icon-ic-premium:before {
  content: '\e907';
  color: #ff7800;
}
.icon-ic-regenerate:before {
  content: '\e908';
}
.icon-ic-social-media:before {
  content: '\e909';
  color: #a6a8aa;
}
.icon-ic-up-arrow:before {
  content: '\e90a';
}
.icon-ic-crown-fill:before {
  content: '\e90b';
}
.icon-ic-affordable:before {
  content: '\e90c';
}
.icon-ic-ai:before {
  content: '\e90d';
}
.icon-ic-customizable:before {
  content: '\e90e';
}
.icon-ic-diverse:before {
  content: '\e90f';
}
.icon-ic-inclusive:before {
  content: '\e910';
}
.icon-ic-secure:before {
  content: '\e911';
}
.icon-ic-user-friendly:before {
  content: '\e912';
}
.icon-ic-plus:before {
  content: '\e913';
}
.icon-ic-minus:before {
  content: '\e914';
}
.icon-ic-current-plan .path1:before {
  content: '\e915';
  color: rgb(245, 128, 30);
}
.icon-ic-current-plan .path2:before {
  content: '\e916';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic-logout:before {
  content: '\e917';
}
.icon-ic-menu:before {
  content: '\e918';
}
