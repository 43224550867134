@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
.landingPage .summary-strip,
.we-are-here {
  background-color: #ffffff;
  padding: 3rem;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  max-width: 80%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
  text-align: center;
  margin: 0 auto;
}

/* Media Breakpoints */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #f6f8fa;
  font-family: 'Inter', sans-serif;
}

.img-full {
  max-width: 100%;
}

.landingPage {
  background-color: #fafafa;
}
.landingPage__section {
  padding: 1.25rem 2.5rem;
}
@media (max-width: 680px) {
  .landingPage__section {
    padding: 1.25rem 1.5rem;
  }
}
.landingPage__section .section-heading {
  font-size: 1.125rem;
  font-family: 'Unbounded', cursive;
  padding-bottom: 2.25rem;
  text-align: center;
}
.landingPage__section .section-sub-heading {
  font-size: 2.25rem;
  text-transform: capitalize;
  font-weight: 600;
  text-align: center;
}
.landingPage__section .main-banner-content .banner-heading {
  font-size: 3rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  font-family: 'Unbounded', cursive;
  margin-bottom: 2.25rem;
}
@media (max-width: 480px) {
  .landingPage__section .main-banner-content .banner-heading {
    font-size: 2rem;
  }
}
.landingPage__section .main-banner-content .banner-heading .light {
  font-weight: 300;
}
.landingPage__section .main-banner-content .banner-description {
  font-size: 1.125rem;
  color: #21262b;
  margin-bottom: 2.25rem;
}
.landingPage__section .main-banner-content .download_details {
  font-size: 0.875rem;
  font-weight: 400;
  color: #21262b;
  display: block;
  margin-top: 2.25rem;
}
.landingPage__section .left-section {
  width: 50%;
}
@media (max-width: 800px) {
  .landingPage__section .left-section {
    width: 100%;
    text-align: center;
  }
}
.landingPage__section .right-section {
  width: 50%;
}
@media (max-width: 800px) {
  .landingPage__section .right-section {
    width: 100%;
    text-align: center;
    margin-bottom: 1.5rem;
  }
}
.landingPage__section.pricing {
  background: #21262b;
  color: #ffffff;
  padding: 5rem 2.5rem;
}
@media (max-width: 680px) {
  .landingPage__section.pricing {
    padding: 3rem 1.5rem;
  }
}
.landingPage__section.pricing .pricing-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
  margin: 3.75rem 0;
}
@media (max-width: 1240px) {
  .landingPage__section.pricing .pricing-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1166px) {
  .landingPage__section.pricing .pricing-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 1024px) {
  .landingPage__section.pricing .pricing-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
  }
}
@media (max-width: 480px) {
  .landingPage__section.pricing .pricing-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
.landingPage__section.pricing .pricing-wrapper__card {
  padding: 1.5rem 1.5rem 5rem 1.5rem;
  border-radius: 4px;
  box-shadow: 2px 2px 0 0 rgba(255, 255, 255, 0.4);
  position: relative;
}
.landingPage__section.pricing .pricing-wrapper__card__heading {
  font-family: 'Unbounded', cursive;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #fff;
  margin-bottom: 2.25rem;
}
.landingPage__section.pricing .pricing-wrapper__card__pricing {
  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 2.25rem;
}
.landingPage__section.pricing .pricing-wrapper__card__pricing sup {
  font-size: 1rem;
  line-height: 1.5;
}
.landingPage__section.pricing .pricing-wrapper__card__pricing .per-month {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}
.landingPage__section.pricing .pricing-wrapper__card__description {
  font-size: 0.875rem;
  line-height: 1.57;
  min-height: 11.625rem;
  margin-bottom: 1rem;
}
.landingPage__section.pricing .pricing-wrapper__card__description p {
  padding-bottom: 1.5rem;
}
.landingPage__section.pricing .pricing-wrapper__card .btn__pricing {
  padding: 0.75rem;
  border-radius: 4px;
  border: solid 1px #df761e;
  background-image: linear-gradient(to bottom, #f94, #f5801e);
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 600;
  width: calc(100% - 3rem);
  color: #fff;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  position: absolute;
  left: 1.5rem;
  bottom: 1.5rem;
}
.landingPage__section.pricing .pricing-wrapper__card .btn__pricing:hover {
  box-shadow: 0 0 15px #7463cb;
}
.landingPage__section.pricing .free-forever {
  display: flex;
}
@media (max-width: 480px) {
  .landingPage__section.pricing .free-forever {
    flex-direction: column;
  }
}
.landingPage__section.pricing .free-forever__heading {
  width: 150px;
  font-family: 'Unbounded', cursive;
  font-weight: 1.125rem;
  font-weight: 600;
}
@media (max-width: 480px) {
  .landingPage__section.pricing .free-forever__heading {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.landingPage__section.pricing .free-forever__description {
  width: calc(100% - 150px);
  font-size: 0.875rem;
  line-height: 1.57;
}
@media (max-width: 480px) {
  .landingPage__section.pricing .free-forever__description {
    width: 100%;
  }
}
.landingPage__section.pricing .free-forever__description p {
  margin-bottom: 1.5rem;
}
.landingPage__section.pricing .free-forever .link-btn {
  font-weight: 600;
}
.landingPage__section.features {
  padding: 5rem 2.5rem;
}
@media (max-width: 680px) {
  .landingPage__section.features {
    padding: 3rem 1.5rem;
  }
}
.landingPage__section.features .features-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5rem;
  grid-row-gap: 2.25rem;
  margin: 3.75rem 0;
}
@media (max-width: 1024px) {
  .landingPage__section.features .features-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 480px) {
  .landingPage__section.features .features-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
.landingPage__section.features .features-wrapper__card {
  text-align: center;
}
.landingPage__section.features .features-wrapper__card__heading {
  padding-bottom: 0.75rem;
  font-size: 1.125rem;
  font-weight: 600;
}
.landingPage__section.features .features-wrapper__card__content {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.57;
  color: #595c60;
}
.landingPage__section.services {
  padding: 5rem 2.5rem;
}
@media (max-width: 680px) {
  .landingPage__section.services {
    padding: 3rem 1.5rem;
  }
}
.landingPage__section.services .flex-40-60 {
  display: flex;
  margin-bottom: 3rem;
  gap: 1.5rem;
}
@media (max-width: 480px) {
  .landingPage__section.services .flex-40-60 {
    flex-direction: column;
  }
}
.landingPage__section.services .flex-40-60 .col-1 {
  width: 40%;
}
.landingPage__section.services .flex-40-60 .col-2 {
  width: 60%;
}
@media (max-width: 1024px) {
  .landingPage__section.services .flex-40-60 .col-1,
  .landingPage__section.services .flex-40-60 .col-2 {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .landingPage__section.services .flex-40-60 .col-1,
  .landingPage__section.services .flex-40-60 .col-2 {
    width: 100%;
  }
}
.landingPage__section.services .services-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
}
@media (max-width: 1024px) {
  .landingPage__section.services .services-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 480px) {
  .landingPage__section.services .services-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
.landingPage__section.services .services-wrapper__card {
  padding: 1.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
}
.landingPage__section.services .services-wrapper__card__icon {
  display: inline-block;
  font-size: 2.5rem;
  color: #bbc4d0;
  margin-bottom: 1.5rem;
}
.landingPage__section.services .services-wrapper__card__heading {
  font-size: 1.125rem;
  line-height: 1.625rem;
  margin-bottom: 0.5rem;
}
.landingPage__section.services .services-wrapper__card p {
  font-size: 0.875rem;
  color: #595c60;
  line-height: 1.375rem;
}
.landingPage__section.faqs {
  padding: 5rem 1.5rem;
}
@media (max-width: 680px) {
  .landingPage .summary-strip {
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
    max-width: 100%;
  }
}
.landingPage .summary-strip__card {
  color: #21262b;
}
.landingPage .summary-strip__card__heading {
  font-size: 2.25rem;
  font-weight: 600;
}
.landingPage .summary-strip__card__caption {
  font-size: 0.875rem;
  font-weight: 400;
}
.landingPage .navbar {
  padding: 1.25rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 680px) {
  .landingPage .navbar {
    padding: 1.25rem 1.5rem;
  }
}
@media (max-width: 680px) {
  .landingPage .navbar {
    padding: 1.25rem 1.5rem;
  }
}
.landingPage .navbar .mobile-hamburger {
  display: none;
  border: none;
  background: none;
  font-size: 1.5rem;
}
.landingPage .navbar .navigation {
  padding: 0;
  margin: 0;
  list-style: none;
}
@media (max-width: 680px) {
  .landingPage .navbar .navigation {
    display: none;
  }
}
.landingPage .navbar .navigation li {
  color: black;
  text-decoration: none;
  display: inline-block;
  padding: 0.75rem 1.5rem;
}
@media (max-width: 680px) {
  .landingPage .navbar .navigation {
    display: flex;
    position: absolute;
    left: 0;
    background: #fff;
    /* height: 100%; */
    flex-direction: column;
    width: 100%;
    text-align: left;
    top: 90px;
    height: 100%;
    padding-top: 20px;
    display: none;
  }
  .landingPage .navbar .signup-btn {
    display: none;
  }
  .landingPage .navbar .mobile-hamburger {
    display: block;
  }
}
.landingPage .section-heading {
  font-size: 1.125rem;
  text-transform: uppercase;
  line-height: 1.625rem;
}
.landingPage .section-heading.blue {
  color: #f5801e;
}

#mainSection .two-section-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../assets/images/main-header-shape.svg) center bottom no-repeat;
  background-size: 100% auto;
}
@media (max-width: 800px) {
  #mainSection .two-section-wrapper {
    flex-direction: column-reverse;
  }
}

.btn__primary {
  border-radius: 0.25rem !important;
  border: solid 1px #df761e;
  background-image: linear-gradient(to bottom, #f94, #f5801e);
  color: #ffffff !important;
  padding: 0.625rem 1.5rem !important;
}

.blue_bg {
  background-image: linear-gradient(to bottom, rgba(237, 242, 246, 0), #edf2f6);
  padding: 1.5rem;
}

.we-are-here {
  justify-content: flex-start;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 680px) {
  .we-are-here {
    max-width: 100%;
  }
}
@media (max-width: 480px) {
  .we-are-here {
    grid-template-columns: repeat(1, 1fr);
  }
}
.we-are-here__heading {
  text-align: left;
}
.we-are-here__details {
  text-align: left;
}
.we-are-here__details .link {
  font-weight: 600;
  color: #21262b;
}
.we-are-here__details .small-txt {
  font-size: 0.875rem;
  color: #595c60;
  margin-top: 1.125rem;
}

.faqs-wrapper {
  max-width: 80%;
  margin: 0 auto;
}
.faqs-wrapper__item {
  border-bottom: 1px solid #ccc;
  transition: 0.5s all;
}
.faqs-wrapper__item .question {
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  padding: 1.125rem 1.5rem 1.125rem 0;
  transition: 0.5s all;
  position: relative;
}
.faqs-wrapper__item .question em {
  font-size: 1.125rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.faqs-wrapper__item .answer {
  display: none;
  font-size: 0.875rem;
  line-height: 1.375rem;
  padding: 0 0 1.5rem 0;
}
.faqs-wrapper__item:hover {
  border-bottom: 1px solid #f5801e;
}
.faqs-wrapper__item:hover .question {
  color: #f5801e;
}
.faqs-wrapper__item.active .answer {
  display: block;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 2.5rem;
}
@media (max-width: 480px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
  }
}
.footer__logo {
  display: flex;
  align-items: center;
  color: #595c60;
}
@media (max-width: 480px) {
  .footer__logo {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    gap: 1rem;
  }
}
.footer__nav {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer__nav li {
  padding: 0.5rem 1.25rem;
  display: inline-block;
}
@media (max-width: 480px) {
  .footer__nav li {
    display: block;
    padding: 0.5rem 1.25rem 0.5rem 0;
    margin-bottom: 0.5rem;
  }
}

