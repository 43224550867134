/* Media Breakpoints */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #f6f8fa;
  font-family: "Inter", sans-serif;
}

.layoutPhrazify {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.langPluginModal {
  background: #fff;
  width: auto;
  max-width: 648px;
  border-radius: 0.5rem;
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  line-height: 1.375rem;
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translate(-50%, 0);
}
.langPluginModal__header {
  padding: 24px;
  border-bottom: 1px solid #d8dce2;
  position: relative;
  background-color: #21262b;
  color: #fff;
  border-radius: 0.5rem 0.5rem 0 0;
}
.langPluginModal__header__close {
  position: absolute;
  right: 24px;
  top: 20px;
  font-size: 1.5rem;
}
.langPluginModal__header h1 {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
}
.langPluginModal__header .iconHeaderBlock {
  border: 1px solid #fff;
  border-radius: 4px;
  display: inline-block;
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-left: 0.75rem;
}
.langPluginModal__body {
  padding: 1.5rem 1.5rem 0 1.5rem;
}
.langPluginModal__footer {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.langPluginModal__footer__copyright {
  font-size: 0.75rem;
  color: #a6a8aa;
}
.langPluginModal__dropdown {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem 0.625rem;
  display: inline-block;
  position: relative;
}
.langPluginModal__dropdown__label {
  padding-right: 1.875rem;
  font-weight: 600;
  position: relative;
}
.langPluginModal__dropdown__label .dropdown-arrow {
  position: absolute;
  right: 0;
  top: -1px;
  font-size: 1.5rem;
}
.langPluginModal__dropdown__menu {
  display: none;
  width: 100%;
  min-width: 150px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  top: 40px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #d8dce2;
  background-color: #fff;
}
.langPluginModal__dropdown__menu ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.langPluginModal__dropdown__menu ul li {
  padding: 8px 30px 8px 11px;
  border-bottom: 1px solid #d8dce2;
  font-weight: 600;
  cursor: pointer;
}
.langPluginModal__dropdown__menu ul li .icon-ic-check {
  display: none;
  font-size: 1.5rem;
  position: absolute;
  right: 10px;
}
.langPluginModal__dropdown__menu ul li:hover {
  background-color: #f3f6fb;
}
.langPluginModal__dropdown__menu ul li:hover .icon-ic-check {
  display: inline-block;
}
.langPluginModal__dropdown.open .langPluginModal__dropdown__menu {
  display: block;
}

.improveTextWrapper {
  border: 1px solid #ccc;
  margin-top: 24px;
  border-radius: 5px;
  overflow: hidden;
}
.improveTextWrapper textarea {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  /*remove the resize handle on the bottom right*/
  min-height: 135px;
  max-height: 135px;
  height: 135px !important;
}
.improveTextWrapper__footer {
  background: #f3f6fb;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 -1px 0 0 #d8dce2;
}
.improveTextWrapper__footer .word-limit label {
  color: #d8dce2;
}

.flat-button__text {
  padding-left: 1.625rem;
  display: inline-block;
  position: relative;
}
.flat-button__text em {
  position: absolute;
  left: 0;
  top: -2px;
  font-size: 1.25rem;
}
.flat-button:hover {
  background-image: linear-gradient(to top, #eaeef3, #fff);
}
.flat-button:active {
  background-image: linear-gradient(to top, #e0e4e8 50%, #f2f7fa 150%);
}

.dropdownStyleModal {
  width: 312px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  font-family: "Inter", sans-serif;
}
.dropdownStyleModal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.125rem;
  border-bottom: 1px solid #d8dce2;
}
.dropdownStyleModal__body {
  padding: 1.125rem;
}
.dropdownStyleModal__list {
  list-style: none;
  padding: 10px 0;
  margin: 1rem 0 0 0;
  border-top: 1px solid #d8dce2;
  border-bottom: 1px solid #d8dce2;
}
.dropdownStyleModal__list__item {
  padding: 10px 0;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.5s all;
}
.dropdownStyleModal__list__item:hover {
  background: #d8dce2;
}
.dropdownStyleModal__list__item > em {
  font-size: 1.5rem;
  margin-right: 1.125rem;
}
.dropdownStyleModal__list.premium {
  margin-top: 0;
  border-top: none;
}
.dropdownStyleModal__btn {
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  border: solid 1px #1585d8;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  background-image: linear-gradient(to top, #1991eb, #2da1f8);
}
.dropdownStyleModal__btn:hover {
  background-image: linear-gradient(to bottom, #1584d7, #2184cf 20%);
}
.dropdownStyleModal__btn:active {
  background-image: linear-gradient(to bottom, #0c73c1, #196baa -33%);
}
.dropdownStyleModal__btn:disabled {
  border: solid 1px #d8dce2;
  background-color: #e3e8ed;
  background-image: unset;
  color: #21262b;
  pointer-events: none;
}
.dropdownStyleModal__footer {
  margin-top: 1.125rem;
  display: flex;
  justify-content: space-evenly;
}
.dropdownStyleModal__footer a {
  font-size: 0.75rem;
  color: #595c60;
  text-decoration: none;
}
.dropdownStyleModal__footer .version {
  font-size: 0.75rem;
  color: #000;
  opacity: 0.5;
}
.dropdownStyleModal__userProfile__title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  margin-top: 0;
}
.dropdownStyleModal__userProfile__email {
  font-size: 0.875rem;
  display: block;
}
.dropdownStyleModal__userProfile__link {
  color: #267ff4;
  text-decoration: none;
  margin-top: 1.5rem;
  display: inline-block;
  font-size: 0.875rem;
}

.frmSign__formGroup {
  margin-bottom: 1.125rem;
}
.frmSign__formGroup input {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #dadada;
  background: #fff;
  border-radius: 4px;
}
.frmSign__formGroup:last-child {
  margin-bottom: 0;
}
.frmSign__btn {
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 4px;
  margin-top: 1rem;
  cursor: pointer;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  border: solid 1px #df761e !important;
  background-image: linear-gradient(to bottom, #f94, #f5801e) !important;
}
.frmSign__btn:hover {
  background-image: unset;
  border: solid 1px #df761e;
  background-color: #f5801e;
}
.frmSign__btn:active {
  background-image: unset;
  border: solid 1px #bb641b;
  background-color: #df761e;
}
.frmSign__btn:disabled {
  border: solid 1px #d8dce2;
  background-color: #e3e8ed;
  background-image: unset;
  color: #21262b;
  pointer-events: none;
}
.frmSign__title {
  font-size: 1.125rem;
  margin-top: 0;
}
.frmSign__forgetPassword {
  font-size: 0.75rem;
  margin-top: 0.625rem;
  text-align: right;
}
.frmSign__forgetPassword a {
  color: #21262b;
  text-decoration: none;
}

.notMember {
  font-size: 0.875rem;
  text-align: center;
}
.notMember a {
  color: #21262b;
  text-decoration: none;
  font-weight: 600;
}

.row_custom {
  display: flex;
}
.row_custom .col-6 {
  width: 50%;
}

.text-right {
  text-align: right;
}

.pr-15 {
  padding-right: 15px;
}

.textLight {
  font-weight: 400;
}

.txtBold {
  font-weight: bold;
}

.layoutPhrazify__nav {
  background: #21262b;
  padding: 18px 20px;
}
.layoutPhrazify__body {
  flex: 1;
  padding: 3rem;
}
@media (max-width: 680px) {
  .layoutPhrazify__body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 1.5rem;
  }
}
.layoutPhrazify__body .main-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 1.5rem;
}
@media (max-width: 680px) {
  .layoutPhrazify__body .main-container {
    flex-direction: column;
    gap: 0.5rem;
  }
}
.layoutPhrazify__body .main-container__side-nav {
  width: 25%;
}
@media (max-width: 680px) {
  .layoutPhrazify__body .main-container__side-nav {
    width: 100%;
  }
}
.layoutPhrazify__body .main-container__content-section {
  width: 75%;
}
@media (max-width: 680px) {
  .layoutPhrazify__body .main-container__content-section {
    width: 100%;
  }
}
.layoutPhrazify__body .main-container .whiteWrapper {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 1.5rem;
}
.layoutPhrazify__body .main-container .whiteWrapper__heading {
  padding: 1.125rem 1.5rem;
  border-bottom: 1px solid #d8dce2;
  font-weight: 600;
}
.layoutPhrazify__body .main-container .whiteWrapper__body {
  padding: 1.5rem;
}
.layoutPhrazify__body .main-container .whiteWrapper__grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  row-gap: 2rem;
  grid-column-gap: 2rem;
}
@media (max-width: 680px) {
  .layoutPhrazify__body .main-container .whiteWrapper__grid {
    grid-template-columns: 1fr 1fr;
  }
}
.layoutPhrazify__body .main-container .whiteWrapper__grid .col > label {
  font-size: 0.75rem;
  color: #21262b;
  display: block;
  margin-bottom: 0.25rem;
}
.layoutPhrazify__body .main-container .whiteWrapper__grid .col .value {
  font-size: 0.875rem;
  font-weight: 600;
  color: #21262b;
  display: block;
}
.layoutPhrazify__darkStrip {
  background: #21262b;
  color: #ffffff;
  text-align: center;
  padding: 3rem;
}
.layoutPhrazify__darkStrip__heading {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}
.layoutPhrazify__darkStrip__tagline {
  font-size: 1rem;
  margin-bottom: 1.125rem;
}
.layoutPhrazify__darkStrip__nrmlTxt {
  font-size: 0.875rem;
  opacity: 0.7;
}
.layoutPhrazify__footer {
  display: flex;
  padding: 3rem;
  justify-content: space-between;
}
@media (max-width: 680px) {
  .layoutPhrazify__footer {
    flex-direction: column;
    text-align: center;
    gap: 1.5rem;
  }
}
.layoutPhrazify__footer__branding {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  color: #595c60;
}
@media (max-width: 680px) {
  .layoutPhrazify__footer__branding {
    justify-content: center;
    flex-direction: column;
  }
  .layoutPhrazify__footer__branding > img {
    margin-bottom: 15px;
  }
}
.layoutPhrazify__footer__branding > img {
  margin-right: 1.125rem;
  max-width: 100px;
}
.layoutPhrazify__footer__nav {
  list-style: none;
}
.layoutPhrazify__footer__nav li {
  display: inline-block;
  margin-left: 3rem;
}
@media (max-width: 680px) {
  .layoutPhrazify__footer__nav li {
    display: block;
    padding: 1.125rem;
    margin-left: 0;
  }
}

.layoutPhrazify__footer_margin {
  margin-top: 227.8px;
}

.paymentMethod {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 680px) {
  .paymentMethod {
    flex-direction: column;
    gap: 1.125rem;
  }
}
.paymentMethod > label {
  font-size: 0.875rem;
}
.paymentMethod__buttons {
  display: flex;
}
@media (max-width: 680px) {
  .paymentMethod__buttons {
    align-self: flex-end;
  }
}

.availablePlans {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
}
@media (max-width: 1166px) {
  .availablePlans {
    grid-template-columns: 1fr 1fr;
  }
}

.apCard {
  border-radius: 4px;
  /* box-shadow: 2px 2px 0 0 #21262b;
  border: solid 1px #21262b; */
  background-color: #fff;
  position: relative;
  background-image: linear-gradient(148deg, #fff1e2 0%, #f6eaed 100%);
}
.apCard__heading {
  /* background-image: linear-gradient(to right, #a8c0ff, #3f2b96); */
  padding: 0.75rem;
  height: 3.5rem;
  display: flex;
  align-items: flex-end;
  position: relative;
  border-bottom: 1px solid #21262b;
}
.apCard__heading h2 {
  font-size: 1rem;
  font-weight: 600;
}
.apCard__heading .selectedIcon {
  font-size: 1.25rem;
  position: absolute;
  left: 0.75rem;
  top: 1rem;
}
.apCard__body {
  font-size: 0.75rem;
  min-height: 178px;
  padding: 1.125rem 0.75rem;
  position: relative;
  padding-bottom: 55px;
}

.apCard button {
  position: absolute;
  left: 50%;
  bottom: 0px;
  width: 90%;
  transform: translate(-50%, -50%);
}

.apCard .btn__primary:disabled {
  color: #fff !important;
  background-color: #21262b !important;
  border: 1px solid #21262b !important;
}

.apCard__price {
  font-size: 0.75rem;
  font-weight: 400;
}
.apCard__price__val {
  font-size: 1.5rem;
  font-weight: 600;
}
.apCard__description {
  font-size: 0.75rem;
  margin-top: 1rem;
  min-height: 6rem;
}

.userProfile__content {
  padding: 1.5rem;
  min-height: 14rem;
}
.userProfile__welcome {
  font-size: 1.125rem;
}
.userProfile__heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem 0;
}
.userProfile__email {
  font-size: 0.875rem;
  display: block;
  word-wrap: break-word;
}
.userProfile__link {
  font-size: 0.875rem;
  color: #267ff4;
  text-decoration: none;
  padding: 1.25rem 1.5rem;
  display: inline-block;
}
.userProfile__logout {
  padding: 1.25rem 1.5rem;
  color: #d14140;
  border-top: 1px solid #d8dce2;
  display: block;
}

.btn__primary {
  width: 100%;
  height: 40px;
  padding: 0.625rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  border: solid 1px #df761e;
  background-image: linear-gradient(to bottom, #f94, #f5801e);
}
.btn__primary:hover {
  background-image: unset;
  border: solid 1px #df761e;
  background-color: #f5801e;
}
.btn__primary:active {
  background-image: unset;
  border: solid 1px #bb641b;
  background-color: #df761e;
}
.btn__primary:disabled {
  border: solid 1px #d8dce2;
  background-color: #e3e8ed !important;
  background-image: unset;
  color: #21262b !important;
  pointer-events: none;
}
.btn__secondary {
  height: 40px;
  margin: 0 0 0 24px;
  padding: 0.625rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  border: solid 1px #d8dce2;
  background-image: linear-gradient(to top, #f2f4f7, #fff);
}
.btn__secondary__text {
  padding-left: 1.625rem;
  display: inline-block;
  position: relative;
}
.btn__secondary__text em {
  position: absolute;
  left: 0;
  top: -2px;
  font-size: 1.25rem;
}
.btn__secondary:hover {
  background-image: linear-gradient(to top, #eaeef3, #fff);
}
.btn__secondary:active {
  background-image: linear-gradient(to top, #e0e4e8 50%, #f2f7fa 150%);
}

.spinner {
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 8px;
  left: calc(50% - 17px);
  background: transparent;
  box-sizing: border-box;
  border-top: 4px solid white;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 100%;
  animation: spin 0.6s ease-out infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.loginWin {
  width: 100%;
  max-width: 872px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  margin: 0 auto;
}
.loginWin__imgWrap {
  width: 50%;
  background: #afddf7 url(../assets/images/login.png) center no-repeat;
  background-size: cover;
  min-height: 497px;
}
@media (max-width: 680px) {
  .loginWin__imgWrap {
    display: none;
  }
}
.loginWin__signIn {
  width: 50%;
  padding: 3rem;
}
@media (max-width: 680px) {
  .loginWin__signIn {
    width: 100%;
  }
}
.loginWin__signIn h1 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}
.loginWin__forgotPassword {
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 1.125rem;
  cursor: pointer;
  color: #1585d8;
}
.loginWin__signup {
  padding: 1.125rem;
  font-size: 0.875rem;
  margin-top: 3rem;
  border-top: 1px solid #d8dce2;
  text-align: center;
}
.loginWin__signup a {
  color: #1585d8;
  font-weight: 600;
  text-decoration: none;
}
@media (max-width: 680px) {
  .loginWin__signup a {
    display: block;
  }
}

.frmItemGroup__label {
  font-size: 0.875rem;
  margin-bottom: 6px;
  display: block;
}
.frmItemGroup__input {
  padding: 0.625rem 0.75rem;
  margin-bottom: 1.125rem;
  border-radius: 4px;
  border: solid 1px #d8dce2;
  background-color: #fff;
  width: 100%;
}

/*# sourceMappingURL=style.css.map */

.verify-in-progress-container {
  margin: auto;
}

.payment-message-box {
  width: 500px;
  aspect-ratio: 3/2;
  background-color: #fff;
}

.payment-message-img-container {
  height: 50%;
  background-color: #41c59d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-message-img {
  filter: invert(100%);
}

.payment-message-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  gap: 0.5rem;
}

.payment-message-title {
  font-weight: 600;
  padding-bottom: 2rem;
}

.payment-message-box button {
  margin: 1rem;
}

.payment-message-description {
  text-align: center;
}

.navbar {
  padding: 1.25rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar .mobile-hamburger {
  display: none;
  border: none;
  background: none;
  font-size: 1.5rem;
}
.navbar ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
@media (max-width: 680px) {
  .navbar {
    padding: 1.25rem 1.5rem;
  }
}
.navbar ul li {
  color: black;
  text-decoration: none;
  display: inline-block;
  padding: 0.75rem 1.5rem;
}
@media (max-width: 680px) {
  .navigation {
    display: flex;
    position: absolute;
    left: 0;
    background: #fff;
    /* height: 100%; */
    flex-direction: column;
    width: 100%;
    text-align: left;
    top: 90px;
    height: 100%;
    padding-top: 20px;
    display: none;
  }
  .navigation.open {
    display: flex;
  }
  .navbar .signup-btn {
    display: none;
  }
  .navbar .mobile-hamburger {
    display: block;
  }
}

.apCard__body p {
  margin-bottom: 22px;
}

.apCard__price {
  font-size: 1rem !important;
  font-weight: 600;
  margin-bottom: 14px;
}

/* Media Query of Phrazify Modal for Mobile Devices */
@media only screen and (max-width: 767px) {
 
  .langPluginModal {
    zoom: 80%; 
  }
  
  .row_custom {
    flex-wrap: wrap;
  }

  .row_custom .col-4 {
    width: 100% !important;
    padding: 3px;
    justify-content: space-between;
  } 
  
  .show-regenerate {
    padding: 0.75rem 1rem !important;
  }

  .improveTextWrapper__footer {
    flex-wrap: wrap;
  }

  .show-regenerate .main-buttons {
    max-width: 100% !important;
  }
  
  .show-regenerate .buttons-wrapper {
    justify-content: space-between !important;
  }

  .main-buttons {
    margin-top: 18px !important;
    min-width: 100% !important;
  }

  .word-limit {
    min-width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .word-limit .pr-15{
    margin: 0 10px 0 10px !important;
  }

  .word-limit .langPluginModal__dropdown{
    margin: 0 10px 0 10px !important;
  }
}