@font-face {
  font-family: 'ext_icons';
  src: url('https://phrazify.s3.ap-southeast-1.amazonaws.com/fonts/ext_icons.eot?x22s1');
  src:
    url('https://phrazify.s3.ap-southeast-1.amazonaws.com/fonts/ext_icons.eot?x22s1#iefix') format('embedded-opentype'),
    url('https://phrazify.s3.ap-southeast-1.amazonaws.com/fonts/ext_icons.ttf?x22s1') format('truetype'),
    url('https://phrazify.s3.ap-southeast-1.amazonaws.com/fonts/ext_icons.woff?x22s1') format('woff'),
    url('https://phrazify.s3.ap-southeast-1.amazonaws.com/fonts/ext_icons.svg?x22s1#ext_icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ext_icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-color-gray {
  color: #a6a8aa;
}

.icon-color-blue {
  color: #2996e6;
}

.icon-color-darkgray {
  color: #595c60;
}

.icon-color-orange {
  color: #ff7800;
}

.icon-ic-ask:before {
  content: '\e900';
}
.icon-ic-check:before {
  content: '\e901';
}
.icon-ic-close:before {
  content: '\e902';
}
.icon-ic-copy:before {
  content: '\e903';
}
.icon-ic-down-arrow:before {
  content: '\e904';
}
.icon-ic-improve-email:before {
  content: '\e905';
}
.icon-ic-improve-text:before {
  content: '\e906';
}
.icon-ic-premium:before {
  content: '\e907';
}
.icon-ic-regenerate:before {
  content: '\e908';
}
.icon-ic-social-media:before {
  content: '\e909';
}
.icon-ic-up-arrow:before {
  content: '\e90a';
}
